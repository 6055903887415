import './Exp.css';
import neoImg from './images/neo_legend.png';
import reaImg from './images/Logo-Realie.jpg';

const myExpSection = (
<div id="vContainer">
	<div class="hContainer">
		<div class="sizeContainerL">
			<img src={neoImg} alt="Logo Neo Legend" className="imagesLogo"/>
		</div>
		<p class="separator"> - </p>
		<div class="sizeContainerR">
			<h2 class="textContainer"><strong>Neo Legend - Contrat d'alternance et CDD</strong></h2>
			<h4 class="textContainer" id="space"> nov. 2021 - oct. 2024 (3 ans)</h4>
			<ul class="textContainerExperience">
				<li>Travailler dans le pôle Recherche et Développement pour intégrer de nouvelles technologies dans les systèmes actuels.</li>
				<li>Identifier les problèmes sur les systèmes et les corriger.</li>
				<li>Développement d'un front-end plus attractif et dynamique.(QML, AutoHotKey)</li>
				<li>Intégration de Steam dans les systèmes.</li>
			</ul>
		</div>
	</div>
	<div class="hContainer">
		<div class="sizeContainerL">
			<img src={reaImg} alt="Logo Neo Legend" className="imagesLogo"/>
		</div>
		<p class="separator"> - </p>
		<div class="sizeContainerR">
			<h2 class="textContainer"><strong>Realie - Stage</strong></h2>
			<h4 class="textContainer" id="space2">juill. 2020 - nov. 2020 (3 mois)</h4>
			<p class="textContainerExperience">
				Dans le cadre d'un stage, j'avais pour projet de développer un système d'exportation de modèle 3D créé sur Blender pour la plateforme Realie en Python.
			</p>
		</div>
	</div>
</div>);
export default myExpSection;